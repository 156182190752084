<template>
  <div>
    <div class="col-12" style="padding-top:30px !important">
      <c-upload 
        :attachInfo="attachInfoJobPlan"
        :editable="editable&&!disabled"
        label="정비오더 첨부파일">
      </c-upload>
    </div>
  </div>
</template>

<script>
// import { uid } from 'quasar';
// import selectConfig from '@/js/selectConfig';
// import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-order-plan-work',
  props: {
    param: {
      type: Object,
      default: () => ({
        workPlanId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        copyFlag: '',
        firstCopy: false,
      }),
    },
    disabledAppr: {
      type: Boolean,
      default: false,
    },
    disabledObj: {
      type: Object,
      default: () => ({
        disabled: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: false,
      attachInfoJobPlan: {
        isSubmit: '',
        taskClassCd: 'WORK_ORDER_PLAN_FILE',
        taskKey: '',
      },
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.$_.cloneDeep(this.param)
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    apprDisabled() {
      return this.disabledAppr;
    },
    disabled() {
      return this.disabledObj.disabled
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$set(this.attachInfoJobPlan, 'taskKey', this.param.workPlanId)
    },
  }
};
</script>